<template>
  <Layout>
    <VContainer fluid>
      <VLayout row wrap justify-center>
        <VCard flat width="400">
          <VCardTitle class="green accent-2">
            <VFlex xs12 mb-2>{{
              wrongPermissions ? 'Unauthorized Page' : 'Page Not Found'
            }}</VFlex>
            <VFlex v-if="resource" xs12 class="title font-weight-bold">{{
              resource
            }}</VFlex>
          </VCardTitle>

          <div class="flex-container flex-centered pa-4">
            <div class="card flex-item">
              <p v-if="wrongPermissions" class="text-xs-left"
                >You do not have permission to view this page. If you believe
                this is a mistake please contact the administrator.</p
              >
              <template v-if="resource">
                <BaseButton color="blue" @click="$router.go(-2)"
                  >Go back to the Previous Page</BaseButton
                >
                <h4>OR</h4>
              </template>
              <RouterLink to="/dashboard">Go back to the Dashboard</RouterLink>
            </div>
          </div>
        </VCard></VLayout
      >
    </VContainer>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'

export default {
  metaInfo: {
    title: 'Page Not Found',
    meta: [{ name: 'description', content: '404' }],
  },
  components: { Layout },
  props: {
    resource: {
      type: String,
      default: '',
    },
    wrongPermissions: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
